<template>
  <div class="d-flex flex-column flex-grow-1">
    <document-toolbar
      :tab="tab"
      :save="saveDocument"
      :revert="refreshDocument"
      :specialActions="userActions"
      v-on="$listeners"
      :show-delete="userIsMasterAdmin && !computedIsActive && isReady"
      :delete-document="deleteDocument"
      :labels.sync="document.labels"
      :show-labels="!documentIsNew"
    />
    <document-in-tab>
      <cot-form
        v-model="isFormValide"
        ref="form"
        :disabled="!canEdit"
      >
        <v-skeleton-loader
          type="image"
          v-if="!id && !documentIsNew"
        />
        <div
          v-else
          class="div-width"
        >
          <v-row dense>
            <v-col
              cols="7"
              class="mb-3"
            >
              <v-card>
                <v-card-title>
                  {{$t('t.User')}}
                  <v-spacer />
                  <div
                    class="d-flex flex-row justify-end"
                    v-if="!documentIsNew"
                  >
                    <v-chip :color="computedCguAcceptedAt ? 'success' : 'error'">
                      <div
                        class="d-flex flex-row"
                        v-if="computedCguAcceptedAt"
                      >
                        <span class="mr-1">{{$t('t.CGUAccepted')}}</span>
                        <span
                          class="mr-1"
                          v-if="cguCalendarLinkText"
                        >{{cguCalendarLinkText}}</span>
                        <calendar-date
                          :date="computedCguAcceptedAt"
                          :lowerCase="true"
                        />
                      </div>
                      <span v-else>
                        {{$t('t.CGUAcceptancePending')}}
                      </span>
                    </v-chip>
                    <v-chip
                      class="ml-2"
                      v-if="computedIsLocked && !unlocked"
                      color="error"
                    >
                      <v-icon class="mr-2">{{$icon('i.Lock')}}</v-icon>
                      <span>{{$t('t.IsLocked')}}</span>
                    </v-chip>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-row
                    dense
                    class="d-flex flox-row align-center"
                  >
                    <v-col>
                      <div class="d-flex flex-row align-center">
                        <v-checkbox
                          :label="$t('t.Active')"
                          v-model="computedIsActive"
                          v-if="!isCurrentUser"
                          class="mr-2"
                        />
                        <v-checkbox
                          v-if="!computedIsActive && !isCurrentUser"
                          :label="$t('t.IsAnonymous')"
                          v-model="computedIsAnonymous"
                          class="mr-2"
                        />
                      </div>
                    </v-col>
                    <v-spacer />
                  </v-row>
                  <v-row dense>
                    <v-col cols="4">
                      <v-text-field
                        :label="$t('t.Username')"
                        v-model="computedUsername"
                        :rules="required"
                      />
                    </v-col>
                    <v-col cols="4">
                      <picker
                        :filters="{ 'roles': groupRolesFilters}"
                        document-types="groups"
                        :selected-id.sync="computedGroupId"
                        :selected-document.sync="computedSelectedGroup"
                        :clearable="false"
                        :rules="required"
                        :label="$t('t.Group')"
                        :dense="false"
                        :disabled="isCurrentUser"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="1">
                      <v-text-field
                        :label="$t('t.Title')"
                        v-model="computedTitle"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        :label="$t('t.Name')"
                        v-model="computedFullname"
                        :rules="required"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        type="email"
                        :prepend-inner-icon="$icon('i.Email')"
                        :label="$t('t.Email')"
                        v-model="computedEmail"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="2">
                      <v-select
                        :items="messagingCultures"
                        item-text="name"
                        item-value="code"
                        :label="$t('t.UserCulture')"
                        v-model="computedCulture"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        :label="$t('t.JobTitle')"
                        v-model="computedJobTitle"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        :label="$t('t.Phone')"
                        v-model="computedPhone1"
                      >
                        <template v-slot:prepend-inner>
                          <phone-btn
                            :phone-number="computedPhone1"
                            :show-phone-number="false"
                            normal
                          />
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        :label="$t('t.Phone2')"
                        v-model="computedPhone2"
                      >
                        <template v-slot:prepend-inner>
                          <phone-btn
                            :phone-number="computedPhone2"
                            :show-phone-number="false"
                            normal
                          />
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        :label="$t('t.Fax')"
                        v-model="computedFax"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-text-field
                        :label="$t('t.AuthenticationClaimValue')"
                        v-model="computedAuthenticationClaimValue"
                        clearable
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    v-if="!isCurrentUser"
                  >
                    <v-col cols="4">
                      <v-text-field
                        class="importKey"
                        :prepend-inner-icon="$icon('i.ImportKey')"
                        :label="$t('t.ImportKey')"
                        v-model="computedImportKey"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox
                        :label="$t('t.IsImportOverwritable')"
                        v-model="computedIsImportOverwritable"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="5"
              class="mb-3"
              v-if="userIsBusinessDivisionSupervisor"
            >
              <v-card class="d-flex flex-column">
                <v-card-title>
                  <span
                    v-if="computedSelectedBusinessDivisions.length > 0"
                    class="mr-2"
                  >{{computedSelectedBusinessDivisions.length}}</span>
                  <span>{{$t("t.BusinessDivisions")}}</span>
                </v-card-title>
                <v-card-text class="d-flex flex-column flex-grow-1">
                  <div class="d-flex align-center">
                    <v-text-field
                      class="flex-grow-0 mr-4 search-divisions mr-3"
                      v-model="filterAllBusinessDivisions"
                      :prepend-inner-icon="$icon('i.Search')"
                      :label="$t('t.Search')"
                      clearable
                    />
                    <v-btn
                      class="mr-3"
                      fab
                      x-small
                      @click="computedOpenAll = true"
                    >
                      <icon-tooltip
                        icon-name="i.ExpandAll"
                        :tooltip-text="$t('t.ExpandAll')"
                      />
                    </v-btn>
                    <v-btn
                      class="mr-3"
                      fab
                      x-small
                      @click="computedOpenAll = false"
                    >
                      <icon-tooltip
                        icon-name="i.CollapseAll"
                        :tooltip-text="$t('t.CollapseAll')"
                      />
                    </v-btn>
                    <v-btn
                      class="mr-3"
                      fab
                      x-small
                      color="error"
                      @click="computedSelectedBusinessDivisions = []"
                      :disabled="computedSelectedBusinessDivisions.length === 0 || isCurrentUser"
                    >
                      <icon-tooltip
                        icon-name="i.UncheckAll"
                        :tooltip-text="$t('t.UnselectAll')"
                      />
                    </v-btn>
                  </div>
                  <div class="divisions-container flex-grow-1">
                    <v-treeview
                      class="divisions"
                      selected-color="primary"
                      dense
                      hoverable
                      :open-all="computedOpenAll"
                      :items="computedAllBusinessDivisions"
                      :search="filterAllBusinessDivisions"
                      :filter="filter"
                      ref="allBusinessDivisionsTree"
                    >
                      <template v-slot:prepend="{item}">
                        <v-btn
                          :disabled="isCurrentUser || !canEdit || !item.hasAccess"
                          @click.stop="nodeClick(item)"
                          icon
                        >
                          <v-icon :color="nodeIsSelected(item) ? 'primary' : ''">{{$icon(nodeIsSelected(item) ? 'i.CheckedCircle' : 'i.Circle')}}</v-icon>
                        </v-btn>
                      </template>
                    </v-treeview>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="7"
              v-if="isReady"
              class="mb-3"
            >
              <v-card>
                <v-card-title>{{$t('t.Signature')}}</v-card-title>
                <v-card-text>
                  <content-editor
                    :height="'200px'"
                    :width="'100%'"
                    class="mb-2 mt-2"
                    :content.sync="computedSignature"
                    :readonly="!canEdit"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="7"
              class="mb-3"
            >
              <v-card v-if="dataNotificationSettings">
                <v-card-title>{{$t('t.Notifications')}}</v-card-title>
                <v-card-text>
                  <v-switch
                    class="label-left"
                    hide-details
                    inset
                    :label="$t('t.DisableMessageNotifications')"
                    v-model="computedNotificationSkipMessages"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </cot-form>
    </document-in-tab>
    <v-dialog
      v-model="showPasswordPopUp"
      max-width=290
      @click:outside="showPasswordPopUp = false"
    >
      <v-card class="pa-0 ma-0">
        <v-card-title>
          <v-icon class="mr-2">{{$icon('i.ChangePassword')}}</v-icon>
          <span>{{$t('t.ChangePassword')}}</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="changePasswordForm"
            v-model="changePasswordForm"
          >
            <v-text-field
              v-if="isCurrentUser"
              v-model="currentPassword"
              :label="$t('t.CurrentPassword')"
              :type="showCurrentPassword ? 'text' : 'password'"
            >
              <template v-slot:append>
                <v-btn
                  icon
                  @click="showCurrentPassword = !showCurrentPassword"
                >
                  <v-icon>{{showCurrentPassword ? $icon('i.Eye') : $icon('i.EyeOff')}} </v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-text-field
              v-model="newPassword"
              :label="$t('t.NewPassword')"
              :type="showNewPassword ? 'text' : 'password'"
              :rules="ruleNew"
              :hint="authenticationRulesDescription"
              persistent-hint
              @input="$refs.changePasswordForm.validate()"
            >
              <template v-slot:append>
                <v-btn
                  icon
                  @click="showNewPassword = !showNewPassword"
                >
                  <v-icon>{{showNewPassword ? $icon('i.Eye') : $icon('i.EyeOff')}} </v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-text-field
              v-model="confirmPassword"
              :label="$t('t.ConfirmPassword')"
              :type="showConfirmPassword ? 'text' : 'password'"
              :rules="ruleConfirmation"
            >
              <template v-slot:append>
                <v-btn
                  icon
                  @click="showConfirmPassword = !showConfirmPassword"
                >
                  <v-icon>{{showConfirmPassword ? $icon('i.Eye') : $icon('i.EyeOff')}} </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="changePassword()"
            :disabled="!newPassword.length || !changePasswordForm"
          >{{$t('t.Save')}}</v-btn>
          <v-btn
            color="primary"
            @click="showPasswordPopUp = false"
          >{{$t('t.Cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import DocEdit from '@/mixins/document-editor'
import Search from '@/pages/search/controllers'
import IncExc from '@/pages/search/controllers/inc-exc'

export default {
  mixins: [DocEdit],
  components: {
    CalendarDate: () => import('@/components/calendar-date'),
    ContentEditor: () => import('@/components/content-editor'),
    CotForm: () => import('@/components/cot-form'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    PhoneBtn: () => import('@/components/phone-btn'),
    Picker: () => import('@/components/picker'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  async created () {
    this.setModule()
    Object.assign(this.tab, { cancel: this.cancel })
  },
  async mounted () {
    this.loadDivisions()
    this.loadCultures()
  },
  computed: {
    computedNotificationSkipMessages: {
      get () {
        return this.dataNotificationSettings.skipMessages
      },
      set (v) {
        this.dataNotificationSettings.skipMessages = v
        this.$set(this.document, 'notificationSettings', this.dataNotificationSettings)
      }
    },
    groupRolesFilters () {
      const roles = new IncExc()
      const items = []
      if (this.$store.getters.currentUserHasPermission('CanEditAdministrator')) {
        items.push('admins')
      }
      if (this.$store.getters.currentUserHasPermission('CanEditAgent')) {
        items.push('users')
      }
      if (this.$store.getters.currentUserHasPermission('CanEditAgentsSupervisor')) {
        items.push('agent-supervisors')
      }
      if (this.$store.getters.currentUserHasPermission('CanEditDivisionSupervisor')) {
        items.push('division-supervisors')
      }
      if (this.$store.getters.currentUserHasPermission('CanEditDisputeResolver')) {
        items.push('dispute-resolvers')
      }
      if (this.$store.getters.currentUserHasPermission('CanEditSalesRepresentative')) {
        items.push('sales-reps')
      }
      roles.include(items)
      return roles
    },
    canEdit () {
      let canEdit = false
      if (!this.documentIsNew) {
        let perm = null
        switch (this.dataSelectedGroup?.code) {
          case 'admins':
            perm = 'CanEditAdministrator'
            break
          case 'users':
            perm = 'CanEditAgent'
            break
          case 'agentSupervisor':
            perm = 'CanEditAgentsSupervisor'
            break
          case 'business_division_supervisor':
            perm = 'CanEditDivisionSupervisor'
            break
          case 'disputeResolvers':
            perm = 'CanEditDisputeResolver'
            break
          case 'sales_representative':
            perm = 'CanEditSalesRepresentative'
            break
          default:
            perm = null
            break
        }
        canEdit = perm !== null && this.$store.getters.currentUserHasPermission(perm)
      } else {
        canEdit = this.$store.getters.currentUserHasPermission('UserSettings')
      }
      const r = canEdit || this.userIsMasterAdmin || this.isCurrentUser
      return r
    },
    isFormValide: {
      get () {
        return this.dataIsFormValide
      },
      set (v) {
        this.dataIsFormValide = v
        this.$emit('document-can-save', { tab: this.tab, value: Boolean(v) })
      }
    },
    computedSelectedGroup: {
      get () {
        return this.dataSelectedGroup
      },
      set (v) {
        this.dataSelectedGroup = v
        this.dataDocumentEmit()
      }
    },
    computedSelectedBusinessDivisions: {
      get () {
        return this.dataSelectedBusinessDivisions
      },
      set (v) {
        this.dataSelectedBusinessDivisions = v
        this.dataDocumentEmit()
      }
    },
    computedUsername: {
      get () {
        return this.dataUsername
      },
      set (v) {
        this.dataUsername = v
        this.dataDocumentEmit()
      }
    },
    computedTitle: {
      get () {
        return this.dataTitle
      },
      set (v) {
        this.dataTitle = v
        this.dataDocumentEmit()
      }
    },
    computedFullname: {
      get () {
        return this.dataFullname
      },
      set (v) {
        this.dataFullname = v
        this.dataDocumentEmit()
      }
    },
    computedEmail: {
      get () {
        return this.dataEmail
      },
      set (v) {
        this.dataEmail = v
        this.dataDocumentEmit()
      }
    },
    computedCulture: {
      get () {
        return this.dataCulture
      },
      set (v) {
        this.dataCulture = v
        this.dataDocumentEmit()
      }
    },
    computedJobTitle: {
      get () {
        return this.dataJobTitle
      },
      set (v) {
        this.dataJobTitle = v
        this.dataDocumentEmit()
      }
    },
    computedPhone1: {
      get () {
        return this.dataPhone1
      },
      set (v) {
        this.dataPhone1 = v
        this.dataDocumentEmit()
      }
    },
    computedPhone2: {
      get () {
        return this.dataPhone2
      },
      set (v) {
        this.dataPhone2 = v
        this.dataDocumentEmit()
      }
    },
    computedFax: {
      get () {
        return this.dataFax
      },
      set (v) {
        this.dataFax = v
        this.dataDocumentEmit()
      }
    },
    computedAuthenticationClaimValue: {
      get () {
        return this.dataAuthenticationClaimValue
      },
      set (v) {
        this.dataAuthenticationClaimValue = v
        this.dataDocumentEmit()
      }
    },
    computedImportKey: {
      get () {
        return this.dataImportKey
      },
      set (v) {
        this.dataImportKey = v
        this.dataDocumentEmit()
      }
    },
    computedIsImportOverwritable: {
      get () {
        return this.dataIsImportOverwritable
      },
      set (v) {
        this.dataIsImportOverwritable = v
        this.dataDocumentEmit()
      }
    },
    computedSignature: {
      get () {
        return this.dataSignature
      },
      set (v) {
        this.dataSignature = v
        this.dataDocumentEmit()
      }
    },
    computedIsActive: {
      get () {
        return this.dataIsActive
      },
      set (v) {
        this.dataIsActive = v
        this.dataDocumentEmit()
      }
    },
    computedCguAcceptedAt: {
      get () {
        return this.dataCguAcceptedAt
      },
      set (v) {
        this.dataCguAcceptedAt = v
        this.dataDocumentEmit()
      }
    },
    computedIsLocked: {
      get () {
        return this.dataIsLocked
      },
      set (v) {
        this.dataIsLocked = v
        this.dataDocumentEmit()
      }
    },
    computedIsAnonymous: {
      get () {
        return this.dataIsAnonymous
      },
      set (v) {
        this.dataIsAnonymous = v
        this.dataDocumentEmit()
      }
    },
    computedGroupId: {
      get () {
        return this.dataGroupId
      },
      set (v) {
        this.dataGroupId = v
        this.dataDocumentEmit()
      }
    },
    computedOpenAll: {
      get () {
        return this.dataOpenAll
      },
      set (v) {
        this.dataOpenAll = v
        this.$refs.allBusinessDivisionsTree.updateAll(v)
      }
    },
    filter (item, search, textKey) {
      return this.dataShowSelectedOnly
        ? (item, search, textKey) => this.nodeIsSelected(item)
        : undefined
    },
    computedAllBusinessDivisions () {
      return this.allBusinessDivisions
    },
    actionItem () {
      return {
        id: this.id,
        type: 'users'
      }
    },
    userIsMasterAdmin () {
      return this.$store.getters.isMasterAdmin()
    },
    userIsBusinessDivisionSupervisor () {
      return this.dataSelectedGroup?.role === 'business_division_supervisor'
    },
    cacheType () {
      return DocEdit.CacheType.UserDetail
    },
    id () {
      return this.tab.documentId
    },
    userActions () {
      if (this.documentIsNew) { return undefined }

      const actions = []
      if (this.isCurrentUser) {
        actions.push({
          icon: this.$icon('i.Cgu'),
          text: this.$t('t.ConsultCGU'),
          callback: this.openCGU
        })
      }

      if (this.isCurrentUser || this.canEdit) {
        actions.push({
          icon: this.$icon('i.ChangePassword'),
          text: this.$t('t.ChangePassword'),
          callback: this.openChangePasswordPopUp
        })
      }

      if (this.computedIsLocked && this.canEdit) {
        actions.push({
          icon: this.$icon('i.Unlock'),
          text: this.$t('t.Unlock'),
          callback: this.unlock
        })
      }
      return actions.length ? actions : undefined
    },
    cguCalendarLinkText () {
      const dateForDiff = this.moment(this.computedCguAcceptedAt)
      const days = Math.abs(this.moment().startOf('day').diff(dateForDiff.startOf('day'), 'days'))

      return days > 1 ? this.$t('t.At').toLowerCase() : null
    },
    isCurrentUser () {
      return this.$store.getters.currentUser.id === this.id
    },
    login () {
      return this.cache.doc.username
    },
    ruleConfirmation () {
      return [v => !this.newPassword.length || this.newPassword === v || this.$t('t.ConfirmationPasswordMismatch')]
    },
    ruleNew () {
      const rule = []
      if (this.authenticationRules?.pattern) {
        const regexp = new RegExp(this.authenticationRules.pattern)
        rule.push(v => regexp.test(v) || this.authenticationRulesDescription)
      }
      return rule
    },
    authenticationRulesDescription () {
      if (this.authenticationRules?.pattern) {
        return this.authenticationRules.patternTranslatedValues.find(t => t.culture === this.$store.getters.currentUser.culture).description
      }
      return null
    }
  },
  data () {
    return {
      dataOpenAll: true,
      dataShowSelectedOnly: false,
      dataNotificationSettings: null,
      dataSelectedGroup: null,
      allBusinessDivisions: [],
      authenticationRules: null,
      changePasswordForm: null,
      confirmPassword: '',
      currentPassword: '',
      dataAuthenticationClaimValue: null,
      dataCguAcceptedAt: false,
      dataCulture: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataEmail: null,
      dataFax: null,
      dataIsFormValide: false,
      dataFullname: null,
      dataGroupId: null,
      dataImportKey: null,
      dataIsActive: true,
      dataIsAnonymous: false,
      dataIsImportOverwritable: true,
      dataIsLocked: false,
      dataJobTitle: null,
      dataPhone1: null,
      dataPhone2: null,
      dataSelectedBusinessDivisions: [],
      dataSignature: null,
      dataTitle: null,
      dataUsername: null,
      defaultCulture: null,
      filterAllBusinessDivisions: '',
      messagingCultures: [],
      newPassword: '',
      required: [v => !!v || this.$t('t.IsRequired')],
      showConfirmPassword: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showPasswordPopUp: false,
      unlocked: false
    }
  },
  methods: {
    emitDocument () {
      const x = {
        authenticationClaimValue: this.dataAuthenticationClaimValue,
        cguAcceptedAt: this.dataCguAcceptedAt,
        culture: this.dataCulture,
        email: this.dataEmail,
        fax: this.dataFax,
        fullname: this.dataFullname,
        groupId: this.dataGroupId,
        id: this.tab.documentId,
        importKey: this.dataImportKey,
        isActive: this.dataIsActive,
        isAnonymous: this.dataIsAnonymous,
        isImportOverwritable: this.dataIsImportOverwritable,
        isLocked: this.dataIsLocked,
        jobTitle: this.dataJobTitle,
        phone1: this.dataPhone1,
        phone2: this.dataPhone2,
        selectedBusinessDivisions: this.dataSelectedBusinessDivisions,
        signature: this.dataSignature,
        title: this.dataTitle,
        username: this.dataUsername
      }

      const doc = Object.assign(this.lodash.cloneDeep(this.document), x)

      if (!this.lodash.isEqual(this.document, doc)) {
        this.document = doc
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    nodeClick (item) {
      const idx = this.dataSelectedBusinessDivisions.indexOf(item.id)
      if (idx === -1) {
        this.dataSelectedBusinessDivisions.push(item.id)
      } else {
        this.dataSelectedBusinessDivisions.splice(idx, 1)
      }
    },
    nodeIsSelected (item) {
      return this.dataSelectedBusinessDivisions.indexOf(item.id) !== -1
    },
    async loadCultures () {
      const r = await this.$http().get('/core/v6/settings/cultures/messaging')
      let { cultures, defaultCulture } = r.data

      cultures = cultures.map(c => {
        return {
          name: this.$t(`t.${c}`),
          code: c
        }
      })

      this.messagingCultures = cultures
      this.defaultCulture = defaultCulture
    },
    async loadNotificationSettings () {
      if (this.isCurrentUser) {
        const r = await this.$http().get('/core/settings/user/notification')
        this.dataNotificationSettings = r.data
      }
    },
    async loadDivisions () {
      const r = await this.$http().get('/core/v6/users/business-divisions-hierarchy')
      this.allBusinessDivisions = [r.data.allBusinessDivisions]
    },
    async deleteDocument () {
      Search.getActions(this.actionItem)
        .get('delete-user')
        .executeQuick(
          this.actionItem,
          () => this.$router.replace('/').catch(e => {
            if (e.name !== 'NavigationDuplicated') {
              throw e
            }
          })
        )
    },
    async authenticateUserWithPassword (password, ignoreComplexity = false) {
      try {
        await this.$http().authenticate(this.login, password, !!window.localStorage.getItem('authentication'))
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.$store.dispatch('showErrorSnackbar', this.$t('t.InvalidUserNameOrPassword'))
        } else if (ignoreComplexity && e.response && e.response.status === 406) {
          // We reach here because the password complexity rules changed after the last password update and the current password does not respect it
          // But we don't care, because we are changing it
          return true
        } else {
          this.$store.dispatch('showErrorSnackbar', e.response.data?.message || this.$t('t.InternalError'))
        }
        return false
      }
      return true
    },
    async refreshDocumentHook () {
      this.loadNotificationSettings()
      if (this.documentIsNew) {
        this.setModule()

        this.document = Object.assign({}, this.document, {
          culture: this.defaultCulture,
          isActive: true,
          businessDivisions: [],
          notificationSettings: null
        })

        if (this.$route.name === 'account') {
          this.link({ id: this.$route.params.id })
        }
      } else {
        // Prevent doing anything if the cache isn't loaded
        if (!this.document?.id) { return }

        this.document = Object.assign({}, this.document, { notificationSettings: this.dataNotificationSettings })

        this.$emit('document-title-change', { tab: this.tab, value: `${this.tab.typeName} ${this.computedFullname}` })
        this.setModule()
      }

      await this.$waitFor(() => this.$refs.form)
        .then(form => setTimeout(() => {
          form.validate()
          this.$emit('document-ready', this.tab)
        }, 500))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    setModule () {
      let moduleData = {
        name: 'User',
        icon: 'i.Users'
      }

      if (this.document?.id) {
        moduleData = {
          componentName: 'user-header',
          data: this.document
        }
      }

      this.$store.commit('setModule', moduleData)
    },
    openChangePasswordPopUp () {
      this.$http().get(`core/authentication/rules?forUser=${this.id}`).then(r => { this.authenticationRules = r.data.regexPattern })
      this.showPasswordPopUp = true
    },
    openCGU () {
      this.$router.push('/cgu')
    },
    async changePassword () {
      // Validate user's current password and refresh session token
      if (this.isCurrentUser && !(await this.authenticateUserWithPassword(this.currentPassword, true))) {
        return
      }

      // Change user password
      try {
        await this.$http().put('/core/authentication/password/update', { id: this.id, password: this.newPassword })
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.$store.dispatch('showErrorSnackbar', this.$t('t.InvalidUserNameOrPassword'))
        } else if (e.response && e.response.status === 406 && e.response.data?.message) {
          this.$store.dispatch('showErrorSnackbar', this.$t(e.response.data?.message))
        } else {
          this.$store.dispatch('showErrorSnackbar')
        }
        return
      }

      // Validate user's new password and get a new session token
      if (this.isCurrentUser && !(await this.authenticateUserWithPassword(this.newPassword))) {
        return
      }

      this.showPasswordPopUp = false

      this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
    },
    unlock () {
      this.$http().put(`/core/v6/users/${this.id}/unlock`).then(() => {
        this.unlocked = true
        this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
      }).catch(e => {
        this.$store.dispatch('showErrorSnackbar', e.message)
      })
    },
    async save () {
      const apiUrl = '/core/v6/users'
      if (this.documentIsNew) {
        const response = await this.$http().post(apiUrl, this.document)
        this.$nextTick(() => {
          this.$router.replace('/users/' + response.data?.id)
        })
        return response
      } else {
        await this.$http().put(`${apiUrl}/${this.id}`, this.document)
      }

      if (this.isCurrentUser) {
        await this.$http().post('/core/settings/user/notification', this.dataNotificationSettings)
      }
      this.refreshDocument()
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    }
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        this.dataAuthenticationClaimValue = v?.authenticationClaimValue
        this.dataCguAcceptedAt = v?.cguAcceptedAt
        this.dataCulture = v?.culture
        this.dataEmail = v?.email
        this.dataFax = v?.fax
        this.dataFullname = v?.fullname
        this.dataGroupId = v?.groupId
        this.dataImportKey = v?.importKey
        this.dataIsActive = v?.isActive
        this.dataIsAnonymous = v?.isAnonymous
        this.dataIsImportOverwritable = v?.isImportOverwritable
        this.dataIsLocked = v?.isLocked
        this.dataJobTitle = v?.jobTitle
        this.dataPhone1 = v?.phone1
        this.dataPhone2 = v?.phone2
        this.dataSelectedBusinessDivisions = v?.selectedBusinessDivisions || []
        this.dataSignature = v?.signature
        this.dataTitle = v?.title
        this.dataUsername = v?.username
      }
    },
    'document.isActive': function (isActive) { // to check
      if (isActive) {
        this.computedIsAnonymous = !isActive
      }
    },
    showPasswordPopUp: function (isShown) {
      if (!isShown) {
        this.confirmPassword = ''
        this.currentPassword = ''
        this.newPassword = ''
        this.showConfirmPassword = false
        this.showCurrentPassword = false
        this.showNewPassword = false
      }
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%

.user-infos
  display flex
  overflow auto
  flex-grow 1
  flex-wrap wrap
  width 100%

.v-card
  height calc(100% - 4px)

  .v-skeleton-loader
    margin 0.5em

.divisions-container
  position relative
  top 0
  right 0
  bottom 0
  left 0

.divisions
  position absolute
  top 0
  right 0
  bottom 0
  left 0
  overflow-y auto
  overscroll-behavior contain

.label-left >>> .v-input__slot
  flex-direction row-reverse

.search-divisions
  min-width 300px
</style>
